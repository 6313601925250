import React, { useEffect, useState } from 'react';
import Column from './components/Column.jsx';
import './scss/app.scss';

const columnsCount = window.innerWidth ? Math.floor(window.innerWidth / 20) : 30;

const createColumns = (count) => {
  const columns = [];
  for (let i = 0; i < count; i++) {
    columns.push({
      column: [' '],
      id: i,
      readyToDisplay: true,
    });
  }
  return columns;
};

export default function App() {
  const [matrixColumns, setMatrixColumns] = useState(createColumns(columnsCount));
  const alphabet = 'abcdefghijklmnopqrstuvwxyz0123456789'.split('');

  useEffect(() => {
    startMatrix();
  }, []);

  const startMatrix = () => {
    setInterval(() => {
      const randomColumnIdx = randomizer(0, columnsCount);
      if (matrixColumns[randomColumnIdx]?.readyToDisplay) {
        setMatrixColumns((prev) => {
          prev[randomColumnIdx].readyToDisplay = false;
          return [...prev];
        });
        createTrack(randomColumnIdx);
      }
    }, 100);
  };

  const createTrack = (idxColumn) => {

    const intervalTrack = setInterval(() => {
      const trackSymbol = randomizer(0, 1);
      setMatrixColumns((prev) => {
        prev[idxColumn].column.push(trackSymbol);
        return [...prev];
      });
    }, randomizer(30, 250));

    const intervalLast = setInterval(() => {
      const lastSymbol = alphabet[randomizer(0, alphabet.length - 1)];
      setMatrixColumns((prev) => {
        prev[idxColumn].column[matrixColumns[idxColumn].column.length - 1] = lastSymbol;
        return [...prev];
      });
    }, 10);

    const intervalRandom = setInterval(() => {
      const randomSymbol = alphabet[randomizer(0, alphabet.length - 1)];
      setMatrixColumns((prev) => {
        prev[idxColumn].column[randomizer(0, matrixColumns[idxColumn].column.length - 1)] =
          randomSymbol;
        return [...prev];
      });
    }, 100);

    setTimeout(() => {
      clearInterval(intervalTrack);
      clearInterval(intervalLast);
    }, 7000);

    setTimeout(() => {
      clearInterval(intervalRandom);
      setMatrixColumns((prev) => {
        prev[idxColumn].column = [' '];
        prev[idxColumn].readyToDisplay = true;
        return [...prev];
      });
    }, randomizer(8000, 15000));
  };

  const randomizer = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  return (
    <div className="wrapper">
      {matrixColumns.map((el, idx) => (
        <Column key={idx} {...el} />
      ))}
    </div>
  );
}
