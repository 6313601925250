import React from 'react';
import Field from './Field.jsx';

export default function Column({ column }) {
  return (
    <div className="column">
      {/* {column.join('')} */}
      {column.map((el, idx) => (
        <Field key={idx} symbol={el} />
      ))}
    </div>
  );
}
